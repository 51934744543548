import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import FeatureHeader from "../components/sections/feature-header"
import FeatureLinks from "../components/sections/feature-links"
import Footer from "../components/sections/footer"

const headline = "Erinnerungsfunktion"
const text =
  "Wenn der Welpe Ihre ganze Aufmerksamkeit benötigt, kann schnell die ein oder andere Aufgabe vergessen werden oder man weiß gar nicht welche Fristen es zu beachten gilt. Der WelpenOrganizer erinnert Sie automatisch zur richtigen Zeit an anfallende Aufgaben."

const Erinnerungsfunktion = () => {
  return (
    <Layout>
      <SEO title={headline} />
      <Navigation />
      <FeatureHeader
        page={"erinnerungsfunktion"}
        headline={headline}
        text={text}
      />
      <FeatureLinks />
      <Footer />
    </Layout>
  )
}

export default Erinnerungsfunktion
